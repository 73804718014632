const INVOICE_OCCASION = "invoice_occasion"
export default {
    namespaced: true,
    state: {
        selectedMembers: [],
        selectedArticles: [],
        selectedInvoiceOccasion: [],
        selectedPaidInvoice: [],
        selectedCreditInvoice: [],
        singleInvoiceOccasion: {}
    },
    getters: {
        GET_SELECT_MEMBERS: state => {
            return state.selectedMembers
        },
        GET_SELECT_ARTICLES: state => {
            return state.selectedArticles
        },
        GET_SELECT_PAID_INVOICE: state => {
            return state.selectedPaidInvoice
        },
        GET_SELECT_CREDIT_INVOICE: state => {
            return state.selectedCreditInvoice
        },
        GET_SINGLE_INVOICE_OCCASION: state => {
            return state.singleInvoiceOccasion
        },
        GET_SELECT_INVOICE_OCCASION: state => {
            return state.selectedInvoiceOccasion
        },
    },
    mutations: {
        UPDATE_SELECTED_MEMBER(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedMembers = [...state.selectedMembers, ...payload.data]
            } else {
                let selected = state.selectedMembers
                selected.push(payload.data)
                state.selectedMembers = selected
            }

        },
        REMOVE_SELECTED_MEMBER(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedMembers = []
            } else {
                let selectedIndex = state.selectedMembers.findIndex(item => item.uuid === payload.uuid)
                let selected = state.selectedMembers
                if (selectedIndex > -1) {
                    selected.splice(selectedIndex, 1)
                    state.selectedMembers = selected
                }
            }

        },
        UPDATE_SELECTED_ARTICLE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedArticles = [...state.selectedArticles, ...payload.data]
            } else {
                let selected = state.selectedArticles
                selected.push(payload.data)
                state.selectedArticles = selected
            }

        },
        REMOVE_SELECTED_ARTICLE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedArticles = []
            } else {
                let selectedIndex = state.selectedArticles.findIndex(item => item.uuid === payload.uuid)
                let selected = state.selectedArticles
                if (selectedIndex > -1) {
                    selected.splice(selectedIndex, 1)
                    state.selectedArticles = selected
                }
            }

        },
        UPDATE_SELECTED_INVOICE_OCCASION(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedInvoiceOccasion = [...state.selectedInvoiceOccasion, ...payload.data]
            } else {
                let selected = state.selectedInvoiceOccasion
                selected.push(payload.data)
                state.selectedInvoiceOccasion = selected
            }

        },
        REMOVE_SELECTED_INVOICE_OCCASION(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedInvoiceOccasion = []
            } else {
                let selectedIndex = state.selectedInvoiceOccasion.findIndex(item => item.uuid === payload.uuid)
                let selected = state.selectedInvoiceOccasion
                if (selectedIndex > -1) {
                    selected.splice(selectedIndex, 1)
                    state.selectedInvoiceOccasion = selected
                }
            }

        },
        UPDATE_SINGLE_INVOICE_OCCASION(state, payload) {
            window.localStorage.setItem(INVOICE_OCCASION, JSON.stringify(payload))
            state.singleInvoiceOccasion = payload
        },
        UPDATE_SELECTED_PAID_INVOICE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedPaidInvoice = [...state.selectedPaidInvoice, ...payload.data]
            } else {
                let selected = state.selectedPaidInvoice
                selected.push(payload.data)
                state.selectedPaidInvoice = selected
            }

        },
        REMOVE_SELECTED_PAID_INVOICE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedPaidInvoice = []
            } else {
                let selectedIndex = state.selectedPaidInvoice.findIndex(item => item.uuid === payload.uuid)
                let selected = state.selectedPaidInvoice
                if (selectedIndex > -1) {
                    selected.splice(selectedIndex, 1)
                    state.selectedPaidInvoice = selected
                }
            }

        },
        UPDATE_SELECTED_CREDIT_INVOICE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedCreditInvoice = [...state.selectedCreditInvoice, ...payload.data]
            } else {
                let selected = state.selectedCreditInvoice
                selected.push(payload.data)
                state.selectedCreditInvoice = selected
            }

        },
        REMOVE_SELECTED_CREDIT_INVOICE(state, payload) {
            if (payload.action === 'bulk') {
                state.selectedCreditInvoice = []
            } else {
                let selectedIndex = state.selectedCreditInvoice.findIndex(item => item.uuid === payload.uuid)
                let selected = state.selectedCreditInvoice
                if (selectedIndex > -1) {
                    selected.splice(selectedIndex, 1)
                    state.selectedCreditInvoice = selected
                }
            }

        },

    },
    actions: {},
}