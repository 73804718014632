import jwtDefaultConfig, { WITHOUT_AUTH_URL } from "./jwtDefaultConfig";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig, auth = false) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // Check if url require without auth
        const without = WITHOUT_AUTH_URL.includes(config.url);

        const useAuth = config.url !== "/auth/check_existing/";

        // If token is present add it to request's Authorization Header
        if (accessToken && useAuth && without === false) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          const lang =
            localStorage.getItem("lang") == "sv"
              ? error.response.data.message
              : error.response.data.eng_message;
          return {
            status: 401,
            message: lang || error.response.data.detail,
          };
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  requestResetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.requestResetPassword, ...args);
  }

  resetUpdatePassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPassword, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  checkExistingUser(...args) {
    return this.axiosIns.post(
      this.jwtConfig.existingUserCheckEndpoint,
      ...args
    );
  }

  packages(...args) {
    return this.axiosIns.get(this.jwtConfig.packages, ...args);
  }

  customSubscription(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.subscriptions}${mode.URL}`,
      ...args
    );
  }

  subscription(...args) {
    return this.axiosIns.get(this.jwtConfig.subscriptions, ...args);
  }

  getPaymentPlan(...args) {
    return this.axiosIns.get(this.jwtConfig.paymentPlan, ...args);
  }

  integrationModule(...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.moduleSubscription}/my_modules`,
      ...args
    );
  }

  getAllModule(...args) {
    return this.axiosIns.get(`${this.jwtConfig.allModules}`, ...args);
  }

  updatePackages(uuid, statusUpdate, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.subscriptions}/update_package/${uuid}/`,
      ...args
    );
  }

  updateSubscription(uuid, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.subscriptions}${uuid}`, ...args);
  }

  cancelSubscription(uuid, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.subscriptions}/cancel/${uuid}/`,
      ...args
    );
  }

  changePaymentPlan(args) {
    return this.axiosIns.put(
      `${this.jwtConfig.subscriptions}/change_payment_plan/${args.id}/`,
      args
    );
  }

  getSmsPlans(...args) {
    return this.axiosIns.get(`${this.jwtConfig.smsPlan}/`, ...args);
  }

  getUsers() {
    return this.axiosIns.get(this.jwtConfig.users);
  }

  getMyAccount() {
    return this.axiosIns.get(`${this.jwtConfig.users}/me`);
  }

  updateUsers(uuid, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.users}/${uuid}`, ...args);
  }

  updateApiConfig(...args) {
    return this.axiosIns.put(`${this.jwtConfig.apiConfig}`, ...args);
  }

  getApiConfig() {
    return this.axiosIns.get(`${this.jwtConfig.apiConfig}`);
  }

  getUsersByid(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.users}/${uuid}`);
  }

  changePassword(...args) {
    return this.axiosIns.post(this.jwtConfig.changePassword, ...args);
  }

  customUser(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.users}${mode.URL}`,
      ...args
    );
  }

  requestIntegration(...args) {
    return this.axiosIns.post(`${this.jwtConfig.requestIntegration}`, ...args);
  }

  updateUser(uuid, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.users}/${uuid}`, ...args);
  }

  deleteUser(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.users}/${uuid}`);
  }

  inviteUsers(...args) {
    return this.axiosIns.post(`${this.jwtConfig.users}/invite`, ...args);
  }

  updateImage(...args) {
    return this.axiosIns.put(`${this.jwtConfig.users}/image`, ...args);
  }

  // uploadTemplateImage(...args) {
  //     return this.axiosIns.put(`${this.jwtConfig.users}/image`, ...args);
  // }

  getCompanyList() {
    return this.axiosIns.get(this.jwtConfig.company);
  }

  getCompanyByid(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.company}/${uuid}`);
  }

  getCurrentUsers() {
    return this.axiosIns.get(`${this.jwtConfig.users}/me`);
  }

  openTicket(...args) {
    return this.axiosIns.post(this.jwtConfig.openTicket, ...args);
  }

  buySupportHours(...args) {
    return this.axiosIns.post(this.jwtConfig.buySupportHours, ...args);
  }

  getDuplicatesList(...args) {
    return this.axiosIns.get(this.jwtConfig.duplicates, ...args);
  }

  getDuplicatesByid(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.duplicates}/${uuid}`);
  }

  getDuplicateDetails(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.duplicates}/${uuid}/details`);
  }

  updateDuplicates(uuid, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.duplicates}/${uuid}`, ...args);
  }

  getHandleGroup(uuid) {
    return this.axiosIns.get(
      `${this.jwtConfig.duplicates}/${uuid}/handle_group`
    );
  }

  updateHandleGroup(uuid) {
    return this.axiosIns.put(
      `${this.jwtConfig.duplicates}/${uuid}/handle_group`
    );
  }

  notDuplicate(uuid, document_num) {
    return this.axiosIns.put(
      `${this.jwtConfig.duplicates}/${uuid}/not_duplicate/${document_num}`
    );
  }

  handleSingle(uuid, document_num) {
    return this.axiosIns.put(
      `${this.jwtConfig.duplicates}/${uuid}/handle_single/${document_num}`
    );
  }

  getFieldMapper(...args) {
    return this.axiosIns.get(this.jwtConfig.fieldMapper, ...args);
  }

  getNotificationsList(...args) {
    return this.axiosIns.get(this.jwtConfig.notifications, ...args);
  }

  getNotificationsById(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.notifications}/${uuid}`);
  }

  deleteNotifications(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.notifications}/${uuid}`);
  }

  updateNotifications(uuid, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.notifications}/${uuid}`,
      ...args
    );
  }

  getBillingsList(...args) {
    return this.axiosIns.get(this.jwtConfig.billing, ...args);
  }

  getCustomBillingList(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.billing}${mode.URL}`,
      ...args
    );
  }

  getBillingsDetail() {
    return this.axiosIns.get(`${this.jwtConfig.billing}/detail`);
  }

  getBillingsHistory() {
    return this.axiosIns.get(`${this.jwtConfig.billing}/`);
  }

  updateBillingsDetail(...args) {
    return this.axiosIns.put(`${this.jwtConfig.billing}/detail`, ...args);
  }

  blogDetails(slug) {
    return this.axiosIns.get(`${this.jwtConfig.listBlog}/${slug}`);
  }

  blogInformation(slug, page) {
    if (page) {
      return this.axiosIns.get(`${this.jwtConfig.listBlog}/${page}`);
    } else if (slug) {
      return this.axiosIns.get(`${this.jwtConfig.listBlog}/${slug}`);
    } else {
      return this.axiosIns.get(`${this.jwtConfig.listBlog}/`);
    }
  }

  customBlog(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.listBlog}${mode.URL}`,
      ...args
    );
  }

  configure(...args) {
    return this.axiosIns.get(this.jwtConfig.configure, ...args);
  }

  updateConfigure(uuid, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.configure}/${uuid}`, ...args);
  }

  customConfiguration(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.configure}${mode.URL}`,
      ...args
    );
  }

  transactionUnique(...args) {
    return this.axiosIns.get(this.jwtConfig.transaction, ...args);
  }

  getResourceList(...args) {
    return this.axiosIns.get(this.jwtConfig.availableResource, ...args);
  }

  faqCategories(...args) {
    return this.axiosIns.get(this.jwtConfig.faqCategories, ...args);
  }

  fetchVideoGuides(...args) {
    return this.axiosIns.get(this.jwtConfig.videoGuides, ...args);
  }

  faqList(...args) {
    if (args) {
      return this.axiosIns.get(`${this.jwtConfig.listfaqByCategories}/${args}`);
    } else
      return this.axiosIns.get(this.jwtConfig.listfaqByCategories, ...args);
  }

  eventLogList(...args) {
    return this.axiosIns.get(this.jwtConfig.eventLog, ...args);
  }

  tierList() {
    return this.axiosIns.get(this.jwtConfig.tierList);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  guide(...args) {
    return this.axiosIns.get(this.jwtConfig.guide, ...args);
  }

  customGuides(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.guide}/${mode.URL}`,
      ...args
    );
  }

  customHelptext(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.helptext}${mode.URL}`,
      ...args
    );
  }

  createNewRole(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.permissions}/${mode.URL}`,
      ...args
    );
  }

  getRole(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissions}/roles`, ...args);
  }

  getScope(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissions}/scopes`, ...args);
  }

  getScopeCategories(...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.permissions}/scope_categories`,
      ...args
    );
  }

  getContractsChart(url, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.contractsTab}${url}`, ...args);
  }

  getCustomersChart(url, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.customerstab}${url}`, ...args);
  }

  getStatistics(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.statisticsMain}${mode.URL}`,
      ...args
    );
  }

  searchCustomers(URL, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.customers}${URL}`, ...args);
  }

  useBaseURLOnly(URL, ...args) {
    return this.axiosIns.get(`${URL}`, ...args);
  }

  customBaseURLOnly(mode, ...args) {
    return this.axiosIns[mode.method](`${mode.URL}`, ...args);
  }

  getCustomers(...args) {
    return this.axiosIns.get(this.jwtConfig.customers, ...args);
  }

  customCustomers(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.customers}${mode.URL}`,
      ...args
    );
  }

  customSync(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.sync}${mode.URL}`,
      ...args
    );
  }

  customization(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.customization}${mode.URL}`,
      ...args
    );
  }

  getCustomersByid(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.customers}/${id}`, ...args);
  }

  getCustomerSegment(...args) {
    return this.axiosIns.get(this.jwtConfig.customersSegments, ...args);
  }

  customSegment(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.segment}${mode.URL}`,
      ...args
    );
  }

  getArticlesSegment(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.segment}/info/articles/${mode.URL}`,
      ...args
    );
  }

  getArticles(...args) {
    return this.axiosIns.get(this.jwtConfig.articles, ...args);
  }

  customArticles(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.articles}${mode.URL}`,
      ...args
    );
  }

  customTags(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.tagLink}${mode.URL}`,
      ...args
    );
  }

  getSuppliers(...args) {
    return this.axiosIns.get(this.jwtConfig.suppliers, ...args);
  }

  searchArticles(URL, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.articles}${URL}`, ...args);
  }

  getInvoices(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.invoices}${mode.URL}`,
      ...args
    );
  }

  getInvoicePayment(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.invoicePayment}${mode.URL}`,
      ...args
    );
  }

  getFeesTypes(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.feesType}${mode.URL}`,
      ...args
    );
  }

  getFees(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.fees}${mode.URL}`,
      ...args
    );
  }

  getFeeRules(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.feesRules}${mode.URL}`,
      ...args
    );
  }

  customFamily(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.family}${mode.URL}`,
      ...args
    );
  }

  sendReceipts(...args) {
    return this.axiosIns.post(this.jwtConfig.sendReceipts, ...args);
  }

  getReceipt(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.receipts}${mode.URL}`,
      ...args
    );
  }

  getPaidInvoices(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.paidInvoices}${mode.URL}`,
      ...args
    );
  }

  // getConfiguration (mode, ...args) {
  //     return this.axiosIns[mode.method](`${this.jwtConfig.receipts}${mode.URL}`, ...args);
  // }

  uploads(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.upload}${mode.URL}`,
      ...args
    );
  }

  getDashboardStats(...args) {
    return this.axiosIns.get(this.jwtConfig.dashboardStats, ...args);
  }

  getContracts(mode, ...args) {
    // if (mode.method === 'get') {
    //     return this.axiosIns[mode.method](this.jwtConfig.contracts, ...args)
    // } else {
    // }
    return this.axiosIns[mode.method](
      `${this.jwtConfig.contracts}${mode.URL}`,
      ...args
    );
  }

  getSubContract(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.subContract}${mode.URL}`,
      ...args
    );
  }

  runScheduleCommand(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.schedules}/${mode.URL}`,
      ...args
    );
  }

  getAllRegisters(URL, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.registers}/${URL}`, ...args);
  }

  getMembers(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.members}${mode.URL}`,
      ...args
    );
  }

  getMemberBilling(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.memberBilling}${mode.URL}`,
      ...args
    );
  }

  getGitMembers(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.gitMembers}${mode.URL}`,
      ...args
    );
  }

  getGitAction(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.gitActions}${mode.URL}`,
      ...args
    );
  }

  getMembersCategory(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.memberCategory}${mode.URL}`,
      ...args
    );
  }

  getMembersPlayer(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.memberPlayer}${mode.URL}`,
      ...args
    );
  }

  // memberCare (url, method,...args) {
  //     return this.axiosIns[method](this.jwtConfig.memberCare + url, ...args);
  // }
  memberCare(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.memberCare}${mode.URL}`,
      ...args
    );
  }

  uploadMemberCareImage(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.memberCare}newsletter/images`,
      ...args
    );
  }

  getLandingPackages(...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.landingEndpoint}/packages`,
      ...args
    );
  }

  getCall(endPoint) {
    return this.axiosIns.get(endPoint);
  }

  getLandingFaq(...args) {
    return this.axiosIns.get(`${this.jwtConfig.landingEndpoint}/faq`, ...args);
  }

  getLandingBlog(...args) {
    return this.axiosIns.get(`${this.jwtConfig.landingEndpoint}/blog`, ...args);
  }

  createContactUsInfo(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.landingEndpoint}/contact_form`,
      ...args
    );
  }

  customLanding(mode, ...args) {
    return this.axiosIns[mode.method](
      `${this.jwtConfig.landingEndpoint}${mode.URL}`,
      ...args
    );
  }

    createContactUsInfo(...args) {
        return this.axiosIns.post(`${this.jwtConfig.landingEndpoint}/contact_form`, ...args);
    }

    createLaunchNotification(...args) {
        return this.axiosIns.post(`${this.jwtConfig.landingEndpoint}/notification_signup`, ...args);
    }

    customLanding(mode, ...args) {
        return this.axiosIns[mode.method](`${this.jwtConfig.landingEndpoint}${mode.URL}`, ...args);
    };

    hiddenResource(mode, ...args) {
        return this.axiosIns[mode.method](`${this.jwtConfig.hiddenResource}${mode.URL}`, ...args);
    };

    getOrganizations(params = {}, customUrl = null) {
      const url = customUrl || `${this.jwtConfig.organizationEndpoint}`;
      return this.axiosIns.get(url, { params });
    };

    downLoadFile(uuid) {
      return this.axiosIns.get(`${this.jwtConfig.billing}/${uuid}/download/`);
    }
}
