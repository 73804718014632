<template>
  <div :class="['d-flex', containerStyle]">
    <div
      class="bg-secondaryColor d-flex bigGap align-items-center"
      :class="btnClass"
      v-if="defaultV"
    >
      <span v-for="(data, index) in tabArray" :key="index">
        <b-button
          :size="buttonSize"
          v-if="data.value === value"
          variant="light"
          v-text="$t(data.label)"
        />

        <p
          v-else
          @click="switchDefaultTabs(index)"
          class="mb-0 text-light"
          role="button"
          v-text="$t(data.label)"
        />
      </span>
    </div>
    <div
      v-else
      class="bg-secondaryColor d-flex bigGap align-items-center"
      :class="btnClass"
    >
      <span v-for="(data, index) in tabArray" :key="index">
        <b-button
          :size="buttonSize"
          v-if="data.active"
          variant="light"
          v-text="$t(data.label)"
        />

        <p
          v-else
          @click="switchTabs(index)"
          class="mb-0 text-light"
          role="button"
          v-text="$t(data.label)"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled:{
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: [],
    },
    btnClass: {
      type: String,
      default: "tabWrapper",
    },
    isToggleAble: {
      type: Boolean,
      default: true,
    },
    buttonSize: {
      type: String,
      default: "",
    },
    containerStyle: {
      type: String,
      default: "justify-content-center justify-content-md-end",
    },
    switchTabs: {
      type: Function,
    },
    value: {},
    defaultValue: {},
  },
  mounted() {
    this.defaultV = this.defaultValue;
  },
  data() {
    return {
      tabArray: [...this.tabs],
      defaultV: false,
    };
  },
  methods: {
    switchDefaultTabs(index) {
      if (this.disabled === true){
        return false
      }
      if (this.isToggleAble) {
        this.tabArray.find((item) => item.active == true).active = false;
        this.$emit("input", this.tabArray[index].value);
        this.tabArray[index].active = true;
      }
    },
  },
};
</script>

<style></style>
