export default [
    {
        path: '/dashboard/configuration/integrations',
        name: 'configurationIntegrations',
        component: () =>
            import('@/views/newViews/configuration/integrations.vue'),
        meta: {
            pageTitle: 'Sidebar.configuration.integrations',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.configuration.integrations',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/configuration/settings',
        name: 'configurationSettings',
        component: () => import('@/views/newViews/configuration/settings.vue'),
        meta: {
            pageTitle: 'Sidebar.configuration.settings',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.configuration.settings',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/configuration/eventLog',
        name: 'configurationEventLog',
        component: () => import('@/views/newViews/configuration/eventLog.vue'),
        meta: {
            pageTitle: 'Sidebar.configuration.eventLog',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.configuration.eventLog',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/configuration/users',
        name: 'configurationUsers',
        component: () => import('@/views/newViews/configuration/users.vue'),
        meta: {
            pageTitle: 'Sidebar.configuration.users',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.configuration.users',
                    active: true,
                },
            ],
        },
    },
];
