export default [
    {
        path: '/dashboard/acounting/article',
        name: 'acountingArticle',
        component: () => import('@/views/newViews/accountingData/articles.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.articles',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.articles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/article/create',
        name: 'acountingCreateArticle',
        component: () => import('@/views/newViews/accountingData/createArticleHolder.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.articles',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.acountingGroup.articles',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/article/edit/:id',
        name: 'acountingEditArticle',
        component: () => import('@/views/newViews/accountingData/editArticleHolder.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.articles',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.acountingGroup.articles',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/article/segment/create',
        name: 'acountingArticleSegmentCreate',
        component: () =>
            import('@/views/newViews/accountingData/ArticleSegmentCreate.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.articles',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.articles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/article/segment/edit/:id',
        name: 'acountingArticleSegmentEdit',
        component: () =>
            import('@/views/newViews/accountingData/ArticleSegmentEdit.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.articles',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.articles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/contracts',
        name: 'acountingContracts',
        component: () =>
            import('@/views/newViews/accountingData/contracts.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.contracts',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.contracts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/contracts/create',
        name: 'createContracts',
        component: () =>
            import('@/views/newViews/accountingData/createContracts.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.contracts',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.contracts',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/dashboard/acounting/contracts/edit/:id',
        name: 'editContracts',
        component: () =>
            import('@/views/newViews/accountingData/editContracts.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.contracts',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.contracts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/invoices',
        name: 'acountingInvoices',
        component: () => import('@/views/newViews/accountingData/invoices.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.invoices',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.invoices',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/invoices/create',
        name: 'acountingCreateInvoices',
        component: () =>
            import('@/views/newViews/accountingData/createInvoices.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.invoices',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.invoices',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/invoices/edit/:id',
        name: 'acountingEditInvoices',
        component: () =>
            import('@/views/newViews/accountingData/editInvoices.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.invoices',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.invoices',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/customers',
        name: 'acountingCustomers',
        component: () =>
            import('@/views/newViews/accountingData/customers.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.customers',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/customers/create',
        name: 'acountingCustomerCreate',
        component: () =>
            import('@/views/newViews/accountingData/customerCreate.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.customers',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/customers/edit/:id',
        name: 'acountingCustomersEdit',
        component: () =>
            import('@/views/newViews/accountingData/editCustomer.vue'),
        meta: {
            editCustomer: true,
            pageTitle: 'Sidebar.acountingGroup.customers',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/customers/segment/create',
        name: 'acountingCustomerSegmentCreate',
        component: () =>
            import('@/views/newViews/accountingData/customerSegmentCreate.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.customers',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/customers/segment/edit/:id',
        name: 'acountingCustomerSegmentEdit',
        component: () =>
            import('@/views/newViews/accountingData/customerSegmentEdit.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.customers',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/acounting/statistics',
        name: 'acountingStatistics',
        component: () =>
            import('@/views/newViews/accountingData/statistics.vue'),
        meta: {
            pageTitle: 'Sidebar.acountingGroup.statistics',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.acountingGroup.statistics',
                    active: true,
                },
            ],
        },
    },
];
