<template>
    <div class="shadow leftCardBottomBorder">
        <div class="w-100 bg-secondaryColor p-1 text-white leftCardTopBorder">
            <p
                class="mb-0 h4 font-weight-bold"
                style="color: inherit"
                v-text="title"
            />
        </div>

        <b-container class="p-1 bg-white rounded-bottom">
            <slot />
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'SecondaryCard',
    props: {
        title: {
            type: String,
        },
    },
};
</script>

<style scoped lang="scss">
.per-page-selector {
    width: 90px;
}

.leftCardTopBorder {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
}

.leftCardBottomBorder {
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
}
</style>
