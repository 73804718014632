<template>
    <div
        class="w-100 d-flex justify-content-center"
        style="gap: 0.5rem; height: 200px"
    >
        <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            class="my-auto"
            label="Spinning"
        ></b-spinner>
        <b-spinner
            style="width: 5rem; height: 5rem"
            type="grow"
            class="my-auto"
            label="Spinning"
        ></b-spinner>
        <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            class="my-auto"
            label="Spinning"
        ></b-spinner>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
