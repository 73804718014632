import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import Loading from '@core/components/loading/Loading.vue';
import Modal from '@/@core/components/modal/modal.vue';
import LittleTabs from '@/@core/components/littetabs/littletabs.vue';
import SecondaryCard from '@/@core/components/secondaryCard/secondaryCard.vue';
import draggable from 'vuedraggable';

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);


Vue.component('draggable' , draggable)

Vue.component('apexchart', VueApexCharts);
Vue.component('Modal', Modal);
Vue.component('LittleTabs', LittleTabs);
Vue.component('SecondaryCard', SecondaryCard);
Vue.component('Loading', Loading);
Vue.component(FeatherIcon.name, FeatherIcon);
