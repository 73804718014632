import Vue from 'vue';
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import {BootstrapVue} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

var VueScrollTo = require('vue-scrollto');


import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';

// Global Components
import './global-components';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {getUserData, getHiddenResource} from '@/auth/utils';
import useAppConfig from '@core/app-config/useAppConfig';
import sevices from './services'

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import {required, email} from '@validations'; // It's important things

Ripple.color = 'rgba(255, 255, 255, 0.15)';

Vue.directive('ripple', Ripple);
// Global event handler
window.Bus = new Vue();
Vue.use(mdiVue, {
    icons: mdijs,
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(sevices);


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(i18n);
// Scrollto Plugin
Vue.use(VueScrollTo)
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

const {currentUser} = useAppConfig();

// Global Mixin
Vue.mixin({
    data() {
        return {
            userData: getUserData(),
            hiddenResource: getHiddenResource()
        };
    },
    computed: {
        swedishLang() {
            return this.defaultLang == 'sv';
        },
        defaultLang() {
            return localStorage.getItem('lang');
        },
    },
    methods: {
        popupMsg(title, text, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    timeout: 3500,
                    title: title,
                    text: text,
                    icon: icon,
                    variant: variant,
                },
            });
        },
        updateMyAccount() {
            useJwt.getMyAccount().then(res => {
                currentUser.value = res.data.data;
            });
        },
    },
});

//Global directive

Vue.directive('tableStyle', (el, binding) => {
    el.style.width = binding.value;
});

import "./assets/style/main.css"

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
