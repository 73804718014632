<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <img src="./../../../assets/icon/accessDenied.png" class="access-img" :height="height" alt="access-denied"/>
      </div>
      <div class="col-md-12">
        <p class="text-center text-lg text-gray-900 font-semibold">{{ $t('accessDenied') }}</p>
      </div>
      <div class="col-md-12">
        <p class="text-center" v-if="message">{{ message }}</p>
        <p class="text-center" v-else>{{ $t('accessDeniedDescription') }}</p>
      </div>
      <!--      <div class="col-md-12 pb-3">-->
      <!--        <b-button-->
      <!--            class="text-center center-item bg-blue-800 access-button"-->
      <!--            v-text=" $t('requestAccess')-->
      <!--            "-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "accessDenied",
  props: {
    height: {
      type: Number,
      default: 300
    },
    message: {
      default: '',
      type: String
    }
  }
}
</script>

<style scoped>
.center-item {
  margin: auto;
  display: table;
}

.access-button {
  background: rgb(30 64 175) !important;
  border: 1px solid transparent !important;
  border-radius: 2px;
}

.access-img {
  /*height: 300px !important;*/
  margin: auto;
  display: table;
}
</style>