export default [
    {
        path: '/dashboard/golfrelated/members',
        name: 'golfRelatedMembers',
        component: () => import('@/views/newViews/golfRelated/members.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.members',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.members',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/dashboard/golfrelated/member-categories',
        name: 'golfRelatedMemberCategory',
        component: () => import('@/views/newViews/golfRelated/memberCategoryOverview.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.members',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.members',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/dashboard/golfrelated/members/detail/:uuid',
        name: 'membersDetail',
        component: () =>
            import('@/views/newViews/golfRelated/membersDetails.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.members',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.members',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/dashboard/golfrelated/create-member',
        name: 'golfRelatedCreateMember',
        component: () => import('@/views/newViews/golfRelated/createMember.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.members',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.members',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/dashboard/golfrelated/exports',
        name: 'golfRelatedExports',
        component: () => import('@/views/newViews/golfRelated/exports.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.exports',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.exports',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/golfrelated/invoice-payment',
        name: 'golfRelatedInvoicePayment',
        component: () => import('@/views/newViews/golfRelated/paidGolfInvoices'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.payment',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.payment',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/golfrelated/receipts',
        name: 'golfRelatedReceipts',
        component: () => import('@/views/newViews/golfRelated/receipts.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.receipts',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.receipts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/golfrelated/fees',
        name: 'golfRelatedFees',
        component: () => import('@/views/newViews/golfRelated/fees.vue'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.fees',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.fees',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/golfrelated/git',
        name: 'golfRelatedGit',
        component: () => import('@/views/newViews/golfRelated/gitComponent'),
        meta: {
            pageTitle: 'Sidebar.golfRelated.git',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.golfRelated.git',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/golfrelated/git/invoice-occasions/:invoice_id',
        name: 'golfRelatedGitSingleInvoiceOccasion',
        component: () => import('@/views/newViews/golfRelated/SingleInvoiceOccasion'),
        meta: {
            pageTitle: '',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: '',
                    active: true,
                },
            ],
        },
    },
];
