export default {
  // Endpoints Gofigo
  loginEndpoint: "auth/login",
  registerEndpoint: "/auth/signup/",
  existingUserCheckEndpoint: "/auth/check_existing/",
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: "/jwt/logout",
  requestResetPassword: "auth/password/reset",
  resetPassword: "auth/password/update_password",
  organizationEndpoint: "/organizations/",

  duplicates: "duplicates",

  //users
  users: "users",

  company: "company",

  requestIntegration: "request_integration/",

  //support
  openTicket: "support/open_ticket",
  buySupportHours: "support/buy_support_hours",

  //notification
  notifications: "notifications",

  // //Dashboard
  billing: "billings",
  // billingDetail: 'billings/detail/',

  //Stats
  dashboardStats: "dashboard/stats",

  // fees
  feesType: "fee_types",

  fees: "fees",

  feesRules: "fee_rules",

  family: "family",

  sendReceipts: "send_receipts/",

  receipts: "receipts",
  paidInvoices: "golf_paid_invoices",

  upload: "uploads",

  //contracts
  contracts: "contracts",

  // sub-contract
  subContract: "configurations/sub-contract",
  apiConfig: "configurations/api",

  // schedules
  schedules: "schedules",

  // registers
  registers: "register",

  // //Tier
  tierList: "duplio_tiers",

  // //Configure / Settings
  configure: "configurations",

  // suppliers
  suppliers: "suppliers",

  //Field mapper
  fieldMapper: "field_mapper",

  transaction: "transaction-id-fields",

  //Available Resource
  availableResource: "available-resources",

  // //Subscription Packages
  packages: "packages",
  subscriptions: "subscriptions",

  paymentPlan: "payment_plans",

  moduleSubscription: "subscription",

  smsPlan: "sms_plans",

  allModules: "modules",

  // //Faq
  faqCategories: "faq_categories",
  videoGuides: "video_guides",
  listfaqByCategories: "faq",

  changePassword: "auth/password/change",

  //guide
  guide: "guides",

  // helptext
  helptext: "helptexts",

  //members
  members: "members",

  // billing
  memberBilling: "member_billing",

  memberCategory: "membercategories",
  memberPlayer: "playercategories",
  memberCare: "member_care/",

  //role
  permissions: "permissions",

  //Statistic pages

  //Contracts tabs
  contractsTab: "statistics/contracts/",
  gitMembers: "git/list_members/",
  gitActions: "git/",

  //Get all customer
  customers: "customers",

  // Sync
  sync: "synchronize",

  //Customers Tabs
  customerstab: "statistics/customers/",

  // statistics main
  statisticsMain: "statistics",

  // customize
  customization: "customizations/",

  //Customers segment
  customersSegments: "segments/customers/",

  // Segment main
  segment: "segments",

  //invoice_payment
  invoicePayment: "invoice_payment",

  //articles
  articles: "articles",

  //invoices
  invoices: "invoices",

  // tags
  tagLink: "tags",

  // invoiceAction : ''

  // event-log
  eventLog: "eventlogs",

  //Blogs
  listBlog: "blog",
  blogCategories: "blog-categories",

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "token",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",

  // landing
  landingEndpoint: "landing_page",
  hiddenResource: "hidden_resources",
};

export const WITHOUT_AUTH_URL = [
  "landing_page/packages",
  "auth/password/changes",
  "auth/password/reset",
  "auth/password/update_password",
  "auth/register",
  "auth/signup",
  "auth/login",
  "/auth/check_existing",
  "landing_page/dashboard_screenshots",
  "landing_page/faq",
];
