export  default [
    {
        path: '',
        name: 'landing-page',
        component: () => import('@/views/FrontPage/Landing.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/blog',
        name: 'blog-page',
        component: () => import('@/views/FrontPage/Blog.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/contact-us',
        name: 'contact-page',
        component: () => import('@/views/FrontPage/Contact.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/functions',
        name: 'functions',
        component: () => import('@/views/FrontPage/Functions.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/FrontPage/FAQ.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/price',
        name: 'price',
        component: () => import('@/views/FrontPage/Price.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/blog-details/:id/:slug',
        name: 'blog-details-page',
        component: () => import('@/views/FrontPage/BlogDetails.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/FrontPage/Login.vue'),
        meta: {
            // mainRoute: true,
            // defaultRoute: true,
            layout: 'full',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/FrontPage/SignUp.vue'),
        meta: {
            // mainRoute: true,
            // defaultRoute: true,
            layout: 'full',
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/FrontPage/ForgetPassword.vue'),
        meta: {
            mainRoute: true,
            defaultRoute: true,
            layout: 'full',
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/FrontPage/ResetPassword.vue'),
        meta: {
            resetPassword: true,
            defaultRoute: true,
            mainRoute: true,
            layout: 'full',
        },
    },
    {
        path: '/complete-profile',
        name: 'complete-profile',
        component: () => import('@/views/FrontPage/completeProfile.vue'),
        meta: {
            defaultRoute: true,
            mainRoute: true,
            layout: 'full',
        },
    },
    {
        path: '/fortnox',
        name: 'fortnox',
        component: () => import('@/views/FrontPage/Fortnox.vue'),
        meta: {
            layout: 'full',
            defaultRoute: true,
        },
    },
]
