<template>
  <b-modal
      v-bind="options"
      @ok="okEvent"
      @close="closeEvent"
      @hide="closeEvent"
      @cancel="closeEvent"
      :hide-footer="hideFooter"
      :hide-header="hideHeader"
      :id="id"
      :title="title"
      centered
      :size="size"
      :hide-backdrop="hideBackDrop"
      :no-close-on-backdrop="noCloseOnBackdrop"
      modal-class="modal-primary"
  >
    <slot/>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    okEvent: {
      type: Function,
      default: () => {
      },
    },
    closeEvent: {
      type: Function,
      default: () => {
      },
    },
    options: {
      type: Object,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm'
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    hideBackDrop: {
      type: Boolean,
      default: false
    }
  },
};
</script>
