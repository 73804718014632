<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view/>
      <Modal

          id="modal-access-denied"
          :hideFooter="true"
          :no-close-on-backdrop="true"
          :hide-header="true"
          :hide-back-drop="true"
      >
        <access-denied :message="accessDeniedMessage" v-if="openAccessDenied === true"/>
      </Modal>
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig';
import {provideToast} from 'vue-toastification/composition';
import {watch} from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

import {useWindowSize, useCssVar} from '@vueuse/core';

import store from '@/store';
import {mapGetters} from "vuex"

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');
const LayoutFullFirst = () => import('@/layouts/full/LayoutFullFirst.vue');
import accessDenied from "@core/components/permission/accessDenied";

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
    LayoutFullFirst, accessDenied
  },
  data() {
    return {
      openAccessDenied: false,
      accessDeniedMessage: ''
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') {
        return 'layout-full';
      } else if (this.$route.meta.layout === 'fullIndex') {
        return `layout-full-first`;
      } else {
        return `layout-${this.contentLayoutType}`;
      }
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
          `--${colors[i]}`,
          document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
              `--breakpoint-${breakpoints[i]}`,
              document.documentElement
          ).value.slice(0, -2)
      );
    }

    // Set RTL
    const {isRTL} = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  setup() {
    const {skin, skinClasses} = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const {width: windowWidth} = useWindowSize();
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
    };
  },
  mounted() {
    window.Bus.$on('output-custom-error', e => {
      /**
       * This event handler handle formatting server errors and displaying appropriate error message as expected
       *
       * Args:
       *    Https error response
       * */
      if (e.response.data.hasOwnProperty('data')) {
        Object.keys(e.response.data.data).map(k => {
          if (e.response.data.data[k] && k) {
            this.popupMsg(
                this.$t("Message.Failed"), `${k} ${e.response.data.data[k].join('')}`,
                "AlertTriangleIcon",
                "danger"
            );
          }
        })
      } else if (e.response.data.hasOwnProperty('error')) {
        Object.keys(e.response.data.error).map(k => {
          if (e.response.data.error[k] && k) {
            this.popupMsg(
                this.$t("Message.Failed"), `${k} ${e.response.data.error[k].join('')}`,
                "AlertTriangleIcon",
                "danger"
            );
          }
        })
      } else {
        let message = this.GET_LOCALE === 'sv' ? e.response.data.message : e.response.data.eng_message
        this.popupMsg(
            this.$t("Message.Failed"),
            message,
            "AlertTriangleIcon",
            "danger"
        );
      }

    })
    window.Bus.$on('trigger-access-denied', e => {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.accessDeniedMessage = e.data[this.locale_message]
      this.openAccessDenied = true
      this.$bvModal.hide("modal-access-denied");
      this.$bvModal.show("modal-access-denied");
    })
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');

.vs__search::placeholder {
  opacity: 0.5 !important;
}

.custom-shadow {
  box-shadow: 5px 5px 3px #888888 !important;
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
</style>