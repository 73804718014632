import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_SERVICE_URL,
    //baseURL: 'https://backend.golfigo.se/v1/',
    // timeout: 1000,
    //headers: {'Access-Control-Allow-Origin': 'http://localhost:8080/'},
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
