export default [
    {
        path: '/dashboard/help/faq',
        name: 'helpFaq',
        component: () => import('@/views/newViews/help/faq.vue'),
        meta: {
            pageTitle: 'Sidebar.help.faq',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.help.faq',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/help/support',
        name: 'helpSupport',
        component: () => import('@/views/newViews/help/support.vue'),
        meta: {
            pageTitle: 'Sidebar.help.support',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.help.support',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/help/video-guides',
        name: 'helpVideoGuide',
        component: () => import('@/views/newViews/help/videoGuides'),
        meta: {
            pageTitle: 'Sidebar.help.videoGuide',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    text: 'Sidebar.help.videoGuide',
                    active: true,
                },
            ],
        },
    },
];
