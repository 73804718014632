export default [
    {
        path: '/dashboard/membercare/email-sendouts',
        name: 'memberCareEmailSendout',
        component: () => import('@/views/newViews/membercare/emailSendout.vue'),
        meta: {
            pageTitle: 'Sidebar.memberCare.emailSendOut',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/membercare/edit-email-template/:uuid',
        name: 'editEmailTemplate',
        component: () => import('@/views/newViews/membercare/EmailTemplate.vue'),
        meta: {
            pageTitle: 'Sidebar.memberCare.emailSendOut',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/membercare/email-templates/',
        name: 'emailTemplateList',
        component: () => import('@/views/newViews/membercare/emailTemplateList'),
        meta: {
            pageTitle: 'Sidebar.memberCare.emailTemplate',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/membercare/create-email-template',
        name: 'createEmailTemplate',
        component: () => import('@/views/newViews/membercare/EmailTemplate.vue'),
        meta: {
            pageTitle: 'Sidebar.memberCare.emailSendOut',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/membercare/sms-sendouts',
        name: 'memberCareSmsSendout',
        component: () => import('@/views/newViews/membercare/smsSendout.vue'),
        meta: {
            pageTitle: 'Sidebar.memberCare.smsSendOut',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/membercare/sms-plans',
        name: 'smsPlanSubscription',
        component: () => import('@/views/newViews/membercare/smsSubscription.vue'),
        meta: {
            pageTitle: 'Sidebar.memberCare.smsSendOut',
            redirectIfLoggedIn: true,
            breadcrumb: [
                {
                    // text: 'Sidebar.golfRelated.members',
                    // active: true,
                },
            ],
        },
    },
];
